<template>
  <div
    class='bar-chart'
    ref='chartdiv'
  ></div>
</template>

<script>
// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { useTheme, create, color } from '@amcharts/amcharts4/core'
import { XYChart, XYCursor, CategoryAxis, ValueAxis, ColumnSeries } from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

useTheme(am4themesAnimated)

export default {
  name: 'bar-chart',
  props: {
    remoteData: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  watch: {
    async remoteData (val) {
      if (this.chart) {
        await this.chart.dispose()
      }
      await this.$nextTick()
      this.setupChart()
    },
  },
  mounted () {
    this.setupChart()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    setupChart () {
      const chart = create(this.$refs.chartdiv, XYChart)

      chart.data = this.remoteData

      // create category axis for years
      const categoryAxis = chart.yAxes.push(new CategoryAxis())
      categoryAxis.dataFields.category = 'name'
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.cursorTooltipEnabled = false

      // create value axis for income and expenses
      const valueAxis = chart.xAxes.push(new ValueAxis())
      valueAxis.cursorTooltipEnabled = false

      // create columns
      const series = chart.series.push(new ColumnSeries())
      series.dataFields.categoryY = 'name'
      series.dataFields.valueX = 'count'
      series.name = 'Income'
      series.columns.template.fillOpacity = 0.5
      series.columns.template.strokeOpacity = 0
      series.columns.template.fill = color(this.$themes.primary)
      series.tooltipText = '{categoryY}: {valueX.value}$'

      // add chart cursor
      chart.cursor = new XYCursor()
      chart.cursor.lineX.disabled = true
      chart.cursor.behavior = 'none'

      this.chart = chart
    },
  },
}
</script>

<style scoped>
.bar-chart {
  width: 100%;
  height: 470px;
}
</style>
